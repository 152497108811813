<template>
  <div style="margin: -15px">
    <div class="header">
      <div>名称：</div><a-input v-model:value="text" placeholder="请输入" style="width: 180px" />
      <a-button style="margin-left: 20px" type="primary" @click="search">查询</a-button>
    </div>
    <div style="border-bottom: 12px solid #F0F2F5">
    </div>
    <div class="box">
      <a-button type="primary" @click="addOpen">+ 新 增</a-button>
      <a-table :pagination="false" style="margin-top: 10px" :loading="spinning" :columns="columns" :data-source="tableData">
        <template slot="operate" slot-scope="text,record, index">
          <a @click="upDown(record.id, 'up')" v-if="index !== 0" style="margin-right: 5px">上移</a>
          <a @click="upDown(record.id, 'down')"  v-if="tableData.length -1 !== index" style="margin-right: 5px">下移</a>
          <a style="margin-right: 5px" @click="editOpen(record)">修改</a>
          <a-popconfirm
              title="是否确认删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delConfirm(record.id)"
          >
            <a style="margin-right: 5px">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
      <div style="margin-top: 30px;padding-left: 20px">
            <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{ pagination.pageNum }} / {{
                Math.ceil(pagination.total / pagination.pageSize)
              }} 页</span>
        <a-pagination
            v-model="pagination.pageNum"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            show-quick-jumper
            show-size-changer
            style="float: right;margin-bottom: 40px"
            @change="pageChange"
            @showSizeChange="pageChange"
        >
        </a-pagination>
      </div>
    </div>
    <a-modal v-model:visible="addVisible" title="新增" @ok="addOk">
      <a-form-model
          ref="addForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="labelContent" label="名称" prop="labelContent">
          <a-input
              placeholder="请输入"
              v-model="form.labelContent"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model:visible="editVisible" title="修改" @ok="editOk">
      <a-form-model
          ref="editForm"
          :model="editForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="labelContent" label="名称" prop="labelContent">
          <a-input
              placeholder="请输入"
              v-model="editForm.labelContent"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

import {
  delExportLabelDelApi,
  getExportLabelApi,
  postExprotLabelAddApi,
  putExportLabelEditApi, putExportLabelUpDown
} from "@/service/zoomMeet_api";

export default {
  name: 'ProficientSetting',
  data () {
    return {
      editVisible: false,
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      rules: {
        labelContent: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
      },
      form: {},
      editForm: {},
      addVisible: false,
      pagination: {   //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'labelContent'
        },
        {
          title: '创建人',
          dataIndex: 'userName'
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          width: 180,
          scopedSlots: { customRender: 'operate' },
        }
      ],
      text: '',
      tableData: [],
      spinning: false
    }//dataReturn
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    async upDown (id, move) {
      this.spinning = true
      const data = {
        id,move
      }
      const res = await putExportLabelUpDown(data)
      if (res.code === 0) {
        this.$message.success('操作成功')
        await this.getTableData()
      } else {
        this.$message.warning(res.message)
      }
      this.spinning = false
    },
    async getTableData () {
      this.spinning = true
      const data = {
        name: this.text,
        pageNo: this.pagination.pageNum,
        pageSum: this.pagination.pageSize,
      }
      const res = await getExportLabelApi(data)
      if (res.code === 0) {
        this.tableData = res.data
        this.pagination.total = res.count
        this.spinning = false
      }
    },
    editOpen (row) {
      this.editForm = {...row}
      this.editVisible = true
      this.$refs.editForm.clearValidate()
    },
    editOk () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const data = {
            ...this.editForm
          }
          const res = await putExportLabelEditApi(data)
          if (res.code === 0) {
            this.$message.success('修改成功')
            this.$refs.editForm.resetFields()
            this.editVisible = false
            await this.getTableData()
          } else {
            this.$message.warning(res.message)
          }
        }
      })
    },
    addOpen () {
      this.addVisible = true
    },
    async addOk () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const data = {
            ...this.form
          }
          const res = await postExprotLabelAddApi(data)
          if (res.code === 0) {
            this.$message.success('新增成功')
            this.$refs.addForm.resetFields()
            this.addVisible = false
            await this.getTableData()
          } else {
            this.$message.warning(res.message)
          }
        }
      })
    },
    async delConfirm (id) {
      this.spinning = true
      const res = await delExportLabelDelApi(id)
      if (res.code === 0) {
        this.$message.success('删除成功')
        await this.getTableData()
      } else {
        this.$message.warning(res.message)
      }
      this.spinning = false
    },
    async search () {
      this.pagination.pageNum = 1
     await this.getTableData()
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no
      this.pagination.pageSize = page_size
      this.getTableData()
    },
  }
}
</script>

<style scoped>
.header {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  /*border-bottom: 10px solid #F0F2F5;*/
}
.box {
  padding: 10px 20px 20px 20px;
}
</style>